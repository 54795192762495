import React, { useEffect, useState } from "react";

const AuthorsLatestArticleVariant = ({ item }) => {
  const [data, setData] = useState([]);

  const fetchData = async (urlVal) => {
    try {
      const response = await fetch(urlVal);
      const jsonData = await response.json();
      setData(jsonData);
      return jsonData;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div data-testid="author-coin-image-sub-content">
      <div class="px-1 w-full" id="coin-image-sub-content-sq">
        <h2
          class="pb-4 font-bold leading-tight text-gray-500 heading-4 md:heading-3"
          id="coin-image-sub-content-heading"
          data-testid="author-coin-image-sub-content-heading">
          {item.attributes.field_heading_text}
        </h2>
        <ul class="flex flex-wrap -mx-5">
          {/* {% for item in authors_data.items %} */}
          <li
            class="w-full md:w-1/2 px-6 py-2 md:py-4 author-coin-li"
            data-pagelocation="landing_page"
            data-dynamictabtitle="Lorem Ipsum 2">
            <div class="flex pt-4 md:pt-2">
              <picture class="flex-shrink-0">
                <source
                  media="(orientation: portrait)"
                  srcset="https://web-dev02.ac.edwardjones.com/sites/default/files/styles/author_coin_image_component/public/acquiadam/2022-07/Angelo%20Kourkafas%20Photo.png.webp?itok=kdsmW5Sf"
                  alt="{{ item.author.imgalt }} 600w"
                />
                <source
                  media="(orientation: landscape) and (min-width: 900px)"
                  srcset="https://web-dev02.ac.edwardjones.com/sites/default/files/styles/author_coin_image_component/public/acquiadam/2022-07/Angelo%20Kourkafas%20Photo.png.webp?itok=kdsmW5Sf"
                  alt="{{ item.author.imgalt }} 800w"
                />
                <img
                  data-testid="author-coin-image-sub-content-image{{ loop.index0 }}"
                  src="https://web-dev02.ac.edwardjones.com/sites/default/files/styles/author_coin_image_component/public/acquiadam/2022-07/Angelo%20Kourkafas%20Photo.png.webp?itok=kdsmW5Sf"
                  class="my-auto rounded-full object-cover w-20 h-20 md:w-44 md:h-44"
                  alt="{{ item.author.imgalt }}"
                  title=""
                  loading="lazy"
                />
              </picture>
              <div class="px-4 md:pt-10">
                <p class="text-sm md:text-xl font-bold">Angelo Kourkafas</p>
                <a
                  data-testid="author-coin-image-sub-content-link0"
                  href="https://web-dev02.ac.edwardjones.com/us-en/market-news-insights/stock-market-news/daily-market-recap"
                  class="pt-2 md:pt-2 text-blue-500 block underline hover:no-underline text-lg leading-tight font-semibold normal-link"
                  id="anchor-link-2180">
                  Daily market snapshot
                  <span
                    aria-hidden="true"
                    class="inline-block pl-2 pt-1 w-3.5 h-3.5">
                    <svg
                      width="7"
                      height="11"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        stroke="currentColor"
                        stroke-width="1.5"
                        d="M1.27.659l4.68 4.634-4.68 4.634"
                        fill="none"
                        fill-rule="evenodd"></path>
                    </svg>
                  </span>
                </a>
                <div
                  class="pt-2 md:pt-4 text-sm font-norma1"
                  data-testid="author-coin-image-sub-content-date0">
                  <span aria-hidden="true">Published Oct. 11, 2023</span>
                  <span class="sr-only">Published October 11, 2023</span>
                </div>
              </div>
            </div>
          </li>
          {/* // {% endfor %} */}
        </ul>
      </div>
    </div>
  );
};

export default AuthorsLatestArticleVariant;
