import React from "react";

const MegaTab = ({ item }) => {
  return (
    <div id="home-tabs">
      <div class="container" data-testid="home" id="loremipsum-mega-tab-sq">
        <div class="heading-3 md:heading-2 md:text-center my-7 tab-main-heading">
          Learn more about
        </div>
        <div data-reach-tabs="" data-orientation="horizontal">
          <div class="lg:max-w-max-content mx-auto mb-4 lg:mb-6 home-tabs">
            <div
              role="tablist"
              aria-orientation="horizontal"
              class="flex lg:justify-left p-2 overflow-x-auto text-sm lg:text-lg"
              data-reach-tab-list="">
              <button
                aria-controls="tabs--1--panel--0"
                aria-selected="true"
                role="tab"
                tabindex="0"
                data-gtm-category="general content"
                data-gtm-action="mega tab click"
                data-gtm-label="Fixed Income"
                data-testid="tab1"
                class="flex-1 mb-1 px-4 py-3 md:px-6 lg:px-9 font-bold text-gray-500 hover:text-gray-500 border-b-2 border-gray-300 whitespace-nowrap hover:bg-gray-100 button-tracking"
                data-reach-tab=""
                data-orientation="horizontal"
                id="tabs--1--tab--0"
                type="button"
                data-selected="">
                Fixed Income
              </button>
              <button
                aria-controls="tabs--1--panel--1"
                aria-selected="false"
                role="tab"
                tabindex="-1"
                data-gtm-category="general content"
                data-gtm-action="mega tab click"
                data-gtm-label="Economic Indicators"
                data-testid="tab2"
                class="flex-1 mb-1 px-4 py-3 md:px-6 lg:px-9 font-bold text-gray-500 hover:text-gray-500 border-b-2 border-gray-300 whitespace-nowrap hover:bg-gray-100 button-tracking"
                data-reach-tab=""
                data-orientation="horizontal"
                id="tabs--1--tab--1"
                type="button">
                Economic Indicators
              </button>
              <button
                aria-controls="tabs--1--panel--2"
                aria-selected="false"
                role="tab"
                tabindex="-1"
                data-gtm-category="general content"
                data-gtm-action="mega tab click"
                data-gtm-label="Brokerage Accounts"
                data-testid="tab3"
                class="flex-1 mb-1 px-4 py-3 md:px-6 lg:px-9 font-bold text-gray-500 hover:text-gray-500 border-b-2 border-gray-300 whitespace-nowrap hover:bg-gray-100 button-tracking"
                data-reach-tab=""
                data-orientation="horizontal"
                id="tabs--1--tab--2"
                type="button">
                Brokerage Accounts
              </button>
            </div>
          </div>
          <div data-reach-tab-panels="">
            <div
              aria-labelledby="tabs--1--tab--0"
              role="tabpanel"
              tabindex="0"
              data-reach-tab-panel=""
              id="tabs--1--panel--0">
              <div id="rte-component-0" class="">
                <div class="rich-text" data-testid="mega-tab-content">
                  {" "}
                  <div class="table-container">
                    <div>
                      <table border="0" cellpadding="0" cellspacing="0">
                        <thead>
                          <tr>
                            <th scope="col">
                              US Treasury Bills, Notes and Bonds
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>3 months</td>
                          </tr>
                          <tr>
                            <td>6 months</td>
                          </tr>
                          <tr>
                            <td>2 years</td>
                          </tr>
                          <tr>
                            <td>5 years</td>
                          </tr>
                          <tr>
                            <td>10 years</td>
                          </tr>
                          <tr>
                            <td>30 years</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div>
                      <table>
                        <thead>
                          <tr>
                            <th scope="col">YTM(%)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>N/A</td>
                          </tr>
                          <tr>
                            <td>N/A</td>
                          </tr>
                          <tr>
                            <td>0.12%</td>
                          </tr>
                          <tr>
                            <td>0.49%</td>
                          </tr>
                          <tr>
                            <td>1.32%</td>
                          </tr>
                          <tr>
                            <td>2.04%</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <p>
                    These are exempt from state income tax and backed by the
                    U.S. Government as to the timely payment of principal and
                    interest.
                  </p>
                  <p>
                    All rates expressed as yield to maturity as of 10/29/2020
                    unless otherwise indicated. Yield and market value will
                    fluctuate if sold prior to maturity, and the amount received
                    from the sale of these securities may be less than the
                    amount originally invested.
                  </p>
                </div>
              </div>
            </div>
            {/* <div
              aria-labelledby="tabs--1--tab--1"
              role="tabpanel"
              tabindex="-1"
              data-reach-tab-panel=""
              id="tabs--1--panel--1"
              hidden="">
              <div id="rte-component-1" class="">
                <div class="rich-text" data-testid="mega-tab-content">
                  {" "}
                  <div class="table-container">
                    <div>
                      <table border="0" cellpadding="0" cellspacing="0">
                        <thead>
                          <tr>
                            <th scope="col">Consumer Price Index</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1.50%</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div>
                      <table>
                        <thead>
                          <tr>
                            <th scope="col">YOY</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Trailing 12 months</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              aria-labelledby="tabs--1--tab--2"
              role="tabpanel"
              tabindex="-1"
              data-reach-tab-panel=""
              id="tabs--1--panel--2"
              hidden="">
              <div id="rte-component-2" class="">
                <div class="rich-text" data-testid="mega-tab-content">
                  {" "}
                  <div class="table-container">
                    <table>
                      <thead>
                        <tr>
                          <th>
                            <p>Brokerage Account</p>

                            <p>(Non-Retirement Accounts Only)</p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <p>
                              <strong>
                                <a
                                  href="/investment-services/account-options/brokerage-account-options"
                                  title="Brokerage Account"
                                  id="anchor-link-2161">
                                  No Minimum
                                </a>
                                &nbsp;
                              </strong>
                            </p>

                            <p>
                              <strong>Hands-On</strong>
                            </p>

                            <p>
                              You make the yes/no decisions for buys and sells.
                            </p>

                            <p>
                              <strong>Flexible</strong>
                            </p>

                            <p>
                              You have the option to use our guidance as a
                              framework.
                            </p>

                            <p>
                              <strong>Broad</strong>
                            </p>

                            <p>
                              You have access to stocks, bonds, CDs, mutual
                              funds, ETFs, annuities and unit investment trusts
                              (UITs).
                            </p>

                            <p>
                              <strong>
                                You Work with Your Financial Advisor
                              </strong>
                            </p>

                            <p>
                              Work with your financial advisor to review your
                              account.
                            </p>

                            <p>
                              <strong>You Decide&nbsp;</strong>
                            </p>

                            <p>
                              Using our investment advice as a framework, you
                              work with your financial advisor to determine if,
                              when and how you'll make changes and rebalance
                              your account.
                            </p>

                            <p>
                              <strong>Statements</strong>
                            </p>

                            <p>
                              You'll receive regular statements, and your
                              financial advisor will contact you with investment
                              updates and ideas.
                            </p>

                            <p>
                              <strong>Commissions</strong>
                            </p>

                            <p>
                              You pay a commission when you buy and sell certain
                              investments, and some investments may have
                              internal expenses.
                            </p>

                            <p>
                              <a
                                class="btn btn-primary w-full"
                                href="/investment-services/account-options/brokerage-account-options"
                                title="Brokerage Account"
                                id="anchor-link-2162">
                                Learn More
                              </a>
                            </p>

                            <p>&nbsp;</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MegaTab;
