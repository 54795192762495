import React, { useEffect, useState } from "react";
import AccordionItem from "../../atoms/AccordionItem/accordionItem.jsx";

const Accordion = ({ item }) => {
  const [data, setData] = useState([]);
  const [accordionBody, setAccordionBody] = useState([]);
  const [urlArr, setUrlArr] = useState([]);

  const fetchData = async (urlVal) => {
    try {
      const response = await fetch(urlVal);
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchAllData = async (urls, body = false) => {
    try {
      const results = await Promise.all(urls.map((url) => fetchData(url)));
      const combinedData = [].concat(...results);
      if (body === true) {
        setAccordionBody(combinedData);
      } else setData(combinedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const urlArray = item.relationships.field_accordion_item.data.map((i) => {
      const type = i.type.split("--");
      const val1 = type[0];
      const val2 = type[1];
      const id = i.id;
      const url = `https://web-dev02.ac.edwardjones.com/us-en/jsonapi/${val1}/${val2}/${id}`;
      return url;
    });
    setUrlArr(urlArray);
    fetchAllData(urlArray);
  }, []);

  useEffect(() => {
    if (data.length && urlArr.length) {
      const urlArrayVal = urlArr.map((i) => {
        const url = i + "/field_accordion_body";
        return url;
      });
      fetchAllData(urlArrayVal, true);
    }
  }, [data, urlArr]);

  return (
    <article
      data-quickedit-entity-id="paragraph/52667836"
      class="accordion accordion-parent container lg:px-28 mt-10 mb-10">
      {accordionBody.length &&
        data.map((i, index) => {
          return (
            <AccordionItem i={i} index={index} accordionBody={accordionBody} />
          );
        })}
    </article>
  );
};

export default Accordion;
