import React from "react";

const Resources = ({ item }) => {
  return (
    <div class="container">
      <h2 class="heading-2 mb-10 text-center" id="social" tabindex="-1">
        More Resources
      </h2>
      <div class="grid lg:grid-cols-3 gap-8">
        <article class="relative lg:col-start-1">
          <picture>
            <source
              media="(orientation: portrait)"
              srcset="https://web-dev02.ac.edwardjones.com/sites/default/files/styles/teaser_image_large/public/acquiadam/2022-07/iStock-1183998991.jpg.webp?itok=A88qvWdC"
              alt="An Edward Jones financial advisor speaks with two elderly clients in his branch office. 600w"
            />
            <source
              media="(orientation: landscape) and (min-width: 900px)"
              srcset="https://web-dev02.ac.edwardjones.com/sites/default/files/styles/teaser_image_large/public/acquiadam/2022-07/iStock-1183998991.jpg.webp?itok=A88qvWdC"
              alt="An Edward Jones financial advisor speaks with two elderly clients in his branch office. 800w"
            />
            <img
              data-gtm-category="resources"
              data-gtm-action="click"
              data-gtm-label="Emotions of Investing"
              src="https://web-dev02.ac.edwardjones.com/sites/default/files/styles/teaser_image_large/public/acquiadam/2022-07/iStock-1183998991.jpg.webp?itok=A88qvWdC"
              class="w-full mb-5 object-cover lg:h-56 lg:w-96"
              alt="An Edward Jones financial advisor speaks with two elderly clients in his branch office."
              title="An Edward Jones financial advisor speaks with two elderly clients in his branch office."
              loading="lazy"
            />
          </picture>
          <h3
            data-gtm-category="resources"
            data-gtm-action="click"
            data-gtm-label="Emotions of Investing"
            class="heading-4 mb-3 truncate-multi-lines"
            id="resources-33511">
            Emotions of Investing
          </h3>
          <p class="text-lg leading-tight mb-3 truncate-multi-lines">
            Emotional investing behaviors that may derail your journey to your
            goals.
          </p>
          <a
            data-gtm-category="get started"
            data-gtm-action="click"
            data-gtm-label="Emotions of Investing"
            href="https://web-dev02.ac.edwardjones.com/us-en/node/33511"
            id="anchor-link-33511"
            class="text-sm text-blue-500 items-center stretched-link font-semibold hover:underline"
            aria-describedby="resources-33511">
            View Understanding the Emotions of Investing
            <svg
              width="7"
              height="11"
              xmlns="http://www.w3.org/2000/svg"
              class="inline-block ml-1 mt-0">
              <path
                d="M1.27.659l4.68 4.634-4.68 4.634"
                stroke="#0076A0"
                stroke-width="1.5"
                fill="none"
                fill-rule="evenodd"></path>
            </svg>
          </a>
        </article>
        <article class="relative lg:col-start-2">
          <picture>
            <source
              media="(orientation: portrait)"
              srcset="
                  https://web-dev02.ac.edwardjones.com/sites/default/files/styles/teaser_image_large/public/acquiadam/2022-07/blank-1x1px.png.webp?itok=NJQtJA7u
                "
              alt=" 600w"
            />
            <source
              media="(orientation: landscape) and (min-width: 900px)"
              srcset="
                  https://web-dev02.ac.edwardjones.com/sites/default/files/styles/teaser_image_large/public/acquiadam/2022-07/blank-1x1px.png.webp?itok=NJQtJA7u
                "
              alt=" 800w"
            />
            <img
              data-gtm-category="resources"
              data-gtm-action="click"
              data-gtm-label="Saving accounts"
              src="https://web-dev02.ac.edwardjones.com/sites/default/files/styles/teaser_image_large/public/acquiadam/2022-07/blank-1x1px.png.webp?itok=NJQtJA7u"
              class="w-full mb-5 object-cover lg:h-56 lg:w-96"
              alt=""
              title=""
              loading="lazy"
            />
          </picture>
          <h3
            data-gtm-category="resources"
            data-gtm-action="click"
            data-gtm-label="Saving accounts"
            class="heading-4 mb-3 truncate-multi-lines"
            id="resources-34201">
            Saving accounts
          </h3>
          <p class="text-lg leading-tight mb-3 truncate-multi-lines">
            Saving accounts
          </p>
          <a
            data-gtm-category="get started"
            data-gtm-action="click"
            data-gtm-label="Saving accounts"
            href="https://web-dev02.ac.edwardjones.com/us-en/why-edward-jones/saving"
            id="anchor-link-34201"
            class="text-sm text-blue-500 items-center stretched-link font-semibold hover:underline"
            aria-describedby="resources-34201">
            Saving accounts
            <svg
              width="7"
              height="11"
              xmlns="http://www.w3.org/2000/svg"
              class="inline-block ml-1 mt-0">
              <path
                d="M1.27.659l4.68 4.634-4.68 4.634"
                stroke="#0076A0"
                stroke-width="1.5"
                fill="none"
                fill-rule="evenodd"></path>
            </svg>
          </a>
        </article>
        <article class="relative lg:col-start-3">
          <picture>
            <source
              media="(orientation: portrait)"
              srcset="
                  https://web-dev02.ac.edwardjones.com/sites/default/files/styles/teaser_image_large/public/acquiadam/2022-07/two-people-viewing-investments-on-ipad-and-laptop-iStock-1186614184.jpg.webp?itok=-DQvSl8e
                "
              alt=" 600w"
            />
            <source
              media="(orientation: landscape) and (min-width: 900px)"
              srcset="
                  https://web-dev02.ac.edwardjones.com/sites/default/files/styles/teaser_image_large/public/acquiadam/2022-07/two-people-viewing-investments-on-ipad-and-laptop-iStock-1186614184.jpg.webp?itok=-DQvSl8e "
              alt=" 800w"
            />
            <img
              data-gtm-category="resources"
              data-gtm-action="click"
              data-gtm-label="Stock Selection"
              src="https://web-dev02.ac.edwardjones.com/sites/default/files/styles/teaser_image_large/public/acquiadam/2022-07/two-people-viewing-investments-on-ipad-and-laptop-iStock-1186614184.jpg.webp?itok=-DQvSl8e"
              class="w-full mb-5 object-cover lg:h-56 lg:w-96"
              alt=""
              title=""
              loading="lazy"
            />
          </picture>
          <h3
            data-gtm-category="resources"
            data-gtm-action="click"
            data-gtm-label="Stock Selection"
            class="heading-4 mb-3 truncate-multi-lines"
            id="resources-31971">
            Stock Selection
          </h3>
          <p class="text-lg leading-tight mb-3 truncate-multi-lines">
            Learn more about how stocks work, and the Edward Jones approach to
            selecting them.
          </p>
          <a
            data-gtm-category="get started"
            data-gtm-action="click"
            data-gtm-label="Stock Selection"
            href="https://web-dev02.ac.edwardjones.com/us-en/node/31971"
            id="anchor-link-31971"
            class="text-sm text-blue-500 items-center stretched-link font-semibold hover:underline"
            aria-describedby="resources-31971">
            View Stock Selection [ &lt; LINK to Stock Selection page &gt; ]
            <svg
              width="7"
              height="11"
              xmlns="http://www.w3.org/2000/svg"
              class="inline-block ml-1 mt-0">
              <path
                d="M1.27.659l4.68 4.634-4.68 4.634"
                stroke="#0076A0"
                stroke-width="1.5"
                fill="none"
                fill-rule="evenodd"></path>
            </svg>
          </a>
        </article>
      </div>
    </div>
  );
};

export default Resources;
