import React, { Component } from "react";

export default class Header extends Component {
  render() {
    return (
      <div
        data-widget="global_header"
        className="relative z-30 bg-white global-header"
        id="header-version-two">
        <div>
          <header
            className="container flex flex-col"
            id="homepage_us_en-header-sq">
            <nav aria-label="primary">
              <div
                id="desktop-header"
                className="border-b border-gray-200 flex items-center justify-end order-first py-1">
                <div className="flex items-center space-x-2">
                  <div
                    className="flex md:flex-col lg:flex-row items-baseline lg:flex-nowrap text-xs leading-snug"
                    data-gtm-category="site nav"
                    data-gtm-action="eyebrow"
                    data-gtm-label="choose a country"
                    data-testid="country">
                    <label className="mr-3 lg:mb-0">Country:</label>
                    <div className="relative whitespace-nowrap flex items-center">
                      <div className="language-switcher" id="language-dropdown">
                        <div>
                          <div className="language-dropdown">
                            <div className="language-switcher-language-url block block-language block-language-blocklanguage-interface">
                              <div className="content">
                                <ul className="links">
                                  <li hrefLang="en">
                                    <a
                                      href="https://www.edwardjones.com/us-en/homepage"
                                      className="language-link is-active"
                                      hrefLang="en"
                                      id="anchor-link-2">
                                      United States | English
                                    </a>
                                  </li>
                                  <li hrefLang="en-CA">
                                    <a
                                      href="https://www.edwardjones.ca/ca-en/homepage"
                                      className="language-link"
                                      hrefLang="en-CA">
                                      Canada | English
                                    </a>
                                  </li>
                                  <li hrefLang="fr-CA">
                                    <a
                                      href="https://www.edwardjones.ca/ca-fr/page-daccueil"
                                      className="language-link"
                                      hrefLang="fr-CA">
                                      Canada | French
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        className="ml-2 focus:outline-none focus:ring button-tracking"
                        aria-label="Choose locale"
                        aria-haspopup="true"
                        aria-expanded="false">
                        <i className="block w-5 h-5" aria-hidden="true">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24">
                            <defs />
                            <path d="M11.93 15.75h.02c.2.01.42-.06.58-.22l5.86-5.86a.75.75 0 10-1.06-1.06l-5.4 5.4-5.4-5.4a.75.75 0 10-1.06 1.06l5.86 5.86c.16.16.38.24.6.22z" />
                          </svg>
                        </i>
                      </button>
                    </div>
                  </div>
                  <button
                    id="search-toggle"
                    data-gtm-category="site nav"
                    data-gtm-action="eyebrow"
                    data-gtm-label="search"
                    data-testid="search-2"
                    aria-label="search Edward Jones"
                    className="w-11 h-11 md:h-10 md:w-10 flex-shrink-0 rounded-full lg:mx-5 md:flex items-center justify-center flex z-40 border-transparent button-tracking"
                    aria-expanded="false">
                    <i
                      className="block w-5 h-5 pointer-events-none"
                      aria-hidden="true">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24">
                        <defs />
                        <path d="M9.99 1.567a8.422 8.422 0 016.462 13.824l5.443 5.444a.75.75 0 11-1.06 1.06l-5.444-5.443A8.422 8.422 0 119.99 1.567zm0 1.5a6.922 6.922 0 104.884 11.828l.01-.012a6.897 6.897 0 002.028-4.894A6.922 6.922 0 009.99 3.067z" />
                      </svg>
                    </i>
                    <span className="sr-only"> </span>
                  </button>
                </div>
              </div>
              <div
                id="desktop-header"
                className="flex items-center justify-between sm:hidden space-x-4 h-16 lg:h-12 lg:mt-3 ej -true">
                <div
                  id="branding"
                  className="inline-flex h-16 lg:h-12 -ml-6 lg:m-0 flex-shrink-0 bg-yellow-500"
                  aria-hidden="false">
                  <button
                    className="global-hamburger-sign flex items-center lg:hidden px-4 z-50 button-tracking"
                    type="button"
                    data-gtm-category="site nav"
                    data-gtm-action="eyebrow"
                    data-gtm-label="hamburger"
                    data-testid="hamburger"
                    aria-expanded="false">
                    <i className="block w-6 h-6" aria-hidden="true">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20">
                        <defs />
                        <path
                          fillRule="evenodd"
                          d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"
                        />
                      </svg>
                    </i>
                    <span className="sr-only">Main Menu</span>
                  </button>
                  <a
                    className="flex flex-shrink-0 items-center px-4"
                    data-gtm-category="site nav"
                    data-gtm-action="header:level 1"
                    data-gtm-label="logo"
                    href="/us-en"
                    id="anchor-link-3">
                    <img
                      alt="Edward Jones"
                      className="block w-24 lg:w-32"
                      src="https://www.edwardjones.com/sites/default/files/2021-01/logo-gray.svg"
                      loading="lazy"
                    />
                  </a>
                </div>
                <div id="rightSection" className="md:flex" aria-hidden="false">
                  <div className="lg:flex-1 main-links-wrapper false mr-6 ej">
                    <ul className="flex -mx-3">
                      <li>
                        <button
                          className="nav-tabs-item h-12 px-1 mx-2 text-sm leading-tight border-transparent button-tracking"
                          type="button"
                          aria-expanded="false"
                          data-gtm-category="site nav"
                          data-gtm-action="header:level 1"
                          data-testid="l1link1"
                          data-gtm-label="Why Edward Jones">
                          Why Edward Jones
                        </button>
                        <div
                          className="absolute left-0 right-0 z-40 focus:outline-none"
                          hidden="">
                          <div className="bg-white shadow-xl pt-12 pb-16">
                            <div className="container">
                              <div
                                className="heading-4 text-2xl"
                                data-testid="tab1heading">
                                Why Edward Jones
                              </div>
                              <p
                                className="text-lg text-gray-500 mb-8"
                                data-testid="tab1para">
                                We’re by your side to help create a better
                                future for you and those around you.
                              </p>
                              <ul className="mega-menu-container text-sm">
                                <li>
                                  <a
                                    className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group heading-5 border-gray-300"
                                    href="/us-en/why-edward-jones/edward-jones-advantage"
                                    data-component-name=""
                                    rel=""
                                    target="_self"
                                    data-gtm-category="site nav"
                                    data-gtm-action="header:level 2"
                                    data-gtm-label="What You Can Expect as a Client"
                                    heading=""
                                    body=""
                                    classexternallink=""
                                    data-testid="l2link1"
                                    id="anchor-link-4">
                                    What You Can Expect as a Client
                                  </a>
                                  <ul>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/why-edward-jones/edward-jones-advantage/partnering-throughout-your-journey"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Partnering Throughout Your Journey"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link1"
                                        id="anchor-link-5">
                                        Partnering Throughout Your Journey
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/why-edward-jones/edward-jones-advantage/online-tools"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Tools to Support Your Goals"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link2"
                                        id="anchor-link-6">
                                        Tools to Support Your Goals
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <a
                                    className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group heading-5 border-gray-300"
                                    href="/us-en/why-edward-jones/investing-approach"
                                    data-component-name=""
                                    rel=""
                                    target="_self"
                                    data-gtm-category="site nav"
                                    data-gtm-action="header:level 2"
                                    data-gtm-label="Our Approach to Investing"
                                    heading=""
                                    body=""
                                    classexternallink=""
                                    data-testid="l2link2"
                                    id="anchor-link-7">
                                    Our Approach to Investing
                                  </a>
                                  <ul>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/why-edward-jones/investing-approach/set-your-path"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Setting Your Path"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link1"
                                        id="anchor-link-8">
                                        Setting Your Path
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/why-edward-jones/investing-approach/investment-philosophy"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Our Investment Philosophy"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link2"
                                        id="anchor-link-9">
                                        Our Investment Philosophy
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/why-edward-jones/investing-approach/how-we-make-recommendations"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="How We Make Recommendations"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link3"
                                        id="anchor-link-10">
                                        How We Make Recommendations
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/why-edward-jones/investing-approach/investment-policy-committee"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Investment Policy Committee"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link4"
                                        id="anchor-link-11">
                                        Investment Policy Committee
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <a
                                    className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group heading-5 border-gray-300"
                                    href="/us-en/why-edward-jones/about-us"
                                    data-component-name=""
                                    rel=""
                                    target="_self"
                                    data-gtm-category="site nav"
                                    data-gtm-action="header:level 2"
                                    data-gtm-label="About Us"
                                    heading=""
                                    body=""
                                    classexternallink=""
                                    data-testid="l2link3"
                                    id="anchor-link-12">
                                    About Us
                                  </a>
                                  <ul>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/why-edward-jones/about-us/our-history"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Our History"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link1"
                                        id="anchor-link-13">
                                        Our History
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/why-edward-jones/about-us/corporate-citizenship"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Corporate Citizenship"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link2"
                                        id="anchor-link-14">
                                        Corporate Citizenship
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/why-edward-jones/about-us/locations"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Our Locations"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link3"
                                        id="anchor-link-15">
                                        Our Locations
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/why-edward-jones/about-us/financial-reports"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Edward Jones Financial Reports"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link4"
                                        id="anchor-link-16">
                                        Edward Jones Financial Reports
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <a
                                    className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group heading-5 border-gray-300"
                                    href="/us-en/why-edward-jones/news-media"
                                    data-component-name=""
                                    rel=""
                                    target="_self"
                                    data-gtm-category="site nav"
                                    data-gtm-action="header:level 2"
                                    data-gtm-label="News and Media"
                                    heading=""
                                    body=""
                                    classexternallink=""
                                    data-testid="l2link4"
                                    id="anchor-link-17">
                                    News and Media
                                  </a>
                                  <ul>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/why-edward-jones/news-media/media-kit"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Media Kit"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link1"
                                        id="anchor-link-18">
                                        Media Kit
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/why-edward-jones/news-media/thought-leadership"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Thought Leadership"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link2"
                                        id="anchor-link-19">
                                        Thought Leadership
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/why-edward-jones/news-media/press-releases"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="News Releases"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link3"
                                        id="anchor-link-20">
                                        News Releases
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/why-edward-jones/news-media/contact-reputation-management"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Contact Reputation Management"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link4"
                                        id="anchor-link-21">
                                        Contact Reputation Management
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <button
                          className="nav-tabs-item h-12 px-1 mx-2 text-sm leading-tight border-transparent button-tracking"
                          type="button"
                          aria-expanded="false"
                          data-gtm-category="site nav"
                          data-gtm-action="header:level 1"
                          data-testid="l1link2"
                          data-gtm-label="Working with a Financial Advisor">
                          Working with a Financial Advisor
                        </button>
                        <div
                          className="absolute left-0 right-0 z-40 focus:outline-none"
                          hidden="">
                          <div className="bg-white shadow-xl pt-12 pb-16">
                            <div className="container">
                              <div
                                className="heading-4 text-2xl"
                                data-testid="tab2heading">
                                Working with a Financial Advisor
                              </div>
                              <p
                                className="text-lg text-gray-500 mb-8"
                                data-testid="tab2para">
                                Help create a better future with a financial
                                advisor who puts you at the center.
                              </p>
                              <ul className="mega-menu-container text-sm">
                                <li>
                                  <a
                                    className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group heading-5 border-gray-300"
                                    href="/us-en/working-financial-advisor/benefits-working-financial-advisor"
                                    data-component-name=""
                                    rel=""
                                    target="_self"
                                    data-gtm-category="site nav"
                                    data-gtm-action="header:level 2"
                                    data-gtm-label="The Benefits of a Financial Advisor"
                                    heading=""
                                    body=""
                                    classexternallink=""
                                    data-testid="l2link1"
                                    id="anchor-link-22">
                                    The Benefits of a Financial Advisor
                                  </a>
                                  <ul />
                                </li>
                                <li>
                                  <a
                                    className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group heading-5 border-gray-300"
                                    href="/us-en/working-financial-advisor/questions-ask-financial-advisor"
                                    data-component-name=""
                                    rel=""
                                    target="_self"
                                    data-gtm-category="site nav"
                                    data-gtm-action="header:level 2"
                                    data-gtm-label="Questions to ask a Financial Advisor"
                                    heading=""
                                    body=""
                                    classexternallink=""
                                    data-testid="l2link2"
                                    id="anchor-link-23">
                                    Questions to ask a Financial Advisor
                                  </a>
                                  <ul />
                                </li>
                                <li>
                                  <a
                                    className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group heading-5 border-gray-300"
                                    href="/us-en/working-financial-advisor/how-choose-financial-advisor"
                                    data-component-name=""
                                    rel=""
                                    target="_self"
                                    data-gtm-category="site nav"
                                    data-gtm-action="header:level 2"
                                    data-gtm-label="How to Choose a Financial Advisor"
                                    heading=""
                                    body=""
                                    classexternallink=""
                                    data-testid="l2link3"
                                    id="anchor-link-24">
                                    How to Choose a Financial Advisor
                                  </a>
                                  <ul>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="https://match.edwardjones.com?ej_referrer=nav"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Match with Financial Advisors"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link1">
                                        Match with Financial Advisors
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="https://www.edwardjones.com/us-en/search/financial-advisor/results"
                                        data-component-name="Navigation_l2_fa_redirect"
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Search Financial Advisors by Location"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link2"
                                        id="anchor-link-25">
                                        Search Financial Advisors by Location
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <a
                                    className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group heading-5 border-gray-300"
                                    href="/us-en/working-financial-advisor/meeting-financial-advisor"
                                    data-component-name=""
                                    rel=""
                                    target="_self"
                                    data-gtm-category="site nav"
                                    data-gtm-action="header:level 2"
                                    data-gtm-label="What an Initial Conversation Looks Like"
                                    heading=""
                                    body=""
                                    classexternallink=""
                                    data-testid="l2link4"
                                    id="anchor-link-26">
                                    What an Initial Conversation Looks Like
                                  </a>
                                  <ul />
                                </li>
                                <li>
                                  <a
                                    className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group heading-5 border-gray-300"
                                    href="/us-en/working-financial-advisor/financial-advisor-relationship"
                                    data-component-name=""
                                    rel=""
                                    target="_self"
                                    data-gtm-category="site nav"
                                    data-gtm-action="header:level 2"
                                    data-gtm-label="Your Financial Advisor Relationship"
                                    heading=""
                                    body=""
                                    classexternallink=""
                                    data-testid="l2link5"
                                    id="anchor-link-27">
                                    Your Financial Advisor Relationship
                                  </a>
                                  <ul />
                                </li>
                                <li>
                                  <a
                                    className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group heading-5 border-gray-300"
                                    href="/us-en/working-financial-advisor/fees"
                                    data-component-name=""
                                    rel=""
                                    target="_self"
                                    data-gtm-category="site nav"
                                    data-gtm-action="header:level 2"
                                    data-gtm-label="Fees and Pricing"
                                    heading=""
                                    body=""
                                    classexternallink=""
                                    data-testid="l2link6"
                                    id="anchor-link-28">
                                    Fees and Pricing
                                  </a>
                                  <ul />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <button
                          className="nav-tabs-item h-12 px-1 mx-2 text-sm leading-tight border-transparent button-tracking"
                          type="button"
                          aria-expanded="false"
                          data-gtm-category="site nav"
                          data-gtm-action="header:level 1"
                          data-testid="l1link3"
                          data-gtm-label="Investment Services">
                          Investment Services
                        </button>
                        <div
                          className="absolute left-0 right-0 z-40 focus:outline-none"
                          hidden="">
                          <div className="bg-white shadow-xl pt-12 pb-16">
                            <div className="container">
                              <div
                                className="heading-4 text-2xl"
                                data-testid="tab3heading">
                                Investment Services
                              </div>
                              <p
                                className="text-lg text-gray-500 mb-8"
                                data-testid="tab3para">
                                Turn your goals into reality while protecting
                                your financial future.
                              </p>
                              <ul className="mega-menu-container text-sm">
                                <li>
                                  <a
                                    className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group heading-5 border-gray-300"
                                    href="/us-en/investment-services/how-we-work-together"
                                    data-component-name=""
                                    rel=""
                                    target="_self"
                                    data-gtm-category="site nav"
                                    data-gtm-action="header:level 2"
                                    data-gtm-label="How We Work Together"
                                    heading=""
                                    body=""
                                    classexternallink=""
                                    data-testid="l2link1"
                                    id="anchor-link-29">
                                    How We Work Together
                                  </a>
                                  <ul>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/how-we-work-together/brokerage"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Edward Jones Select Account"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link1"
                                        id="anchor-link-30">
                                        Edward Jones Select Account
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/how-we-work-together/guided-flex-account"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Edward Jones Guided Solutions Flex Account"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link2"
                                        id="anchor-link-31">
                                        Edward Jones Guided Solutions Flex
                                        Account
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/how-we-work-together/guided-solutions-fund-account"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Edward Jones Guided Solutions Fund Account"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link3"
                                        id="anchor-link-32">
                                        Edward Jones Guided Solutions Fund
                                        Account
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/how-we-work-together/unified-managed-account-models"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Edward Jones Advisory Solutions UMA Models"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link4"
                                        id="anchor-link-33">
                                        Edward Jones Advisory Solutions UMA
                                        Models
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/how-we-work-together/fund-models"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Edward Jones Advisory Solutions Fund Models"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link5"
                                        id="anchor-link-34">
                                        Edward Jones Advisory Solutions Fund
                                        Models
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <a
                                    className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group heading-5 border-gray-300"
                                    href="/us-en/investment-services/account-options"
                                    data-component-name=""
                                    rel=""
                                    target="_self"
                                    data-gtm-category="site nav"
                                    data-gtm-action="header:level 2"
                                    data-gtm-label="Account Options"
                                    heading=""
                                    body=""
                                    classexternallink=""
                                    data-testid="l2link2"
                                    id="anchor-link-35">
                                    Account Options
                                  </a>
                                  <ul>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/account-options/retirement"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Retirement Accounts"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link1"
                                        id="anchor-link-36">
                                        Retirement Accounts
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/account-options/brokerage"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Brokerage Account Options"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link2"
                                        id="anchor-link-37">
                                        Brokerage Account Options
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/account-options/what-529-plan"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="529 Plans"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link3"
                                        id="anchor-link-38">
                                        529 Plans
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/account-options/cash-credit"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Cash and Credit"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link4"
                                        id="anchor-link-39">
                                        Cash and Credit
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <a
                                    className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group heading-5 border-gray-300"
                                    href="/us-en/investment-services/wealth-strategies"
                                    data-component-name=""
                                    rel=""
                                    target="_self"
                                    data-gtm-category="site nav"
                                    data-gtm-action="header:level 2"
                                    data-gtm-label="Wealth Strategies"
                                    heading=""
                                    body=""
                                    classexternallink=""
                                    data-testid="l2link3"
                                    id="anchor-link-40">
                                    Wealth Strategies
                                  </a>
                                  <ul>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/wealth-strategies/advisory-solutions"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Edward Jones Advisory Solutions"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link1"
                                        id="anchor-link-41">
                                        Edward Jones Advisory Solutions
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/wealth-strategies/guided-solutions"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Edward Jones Guided Solutions"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link2"
                                        id="anchor-link-42">
                                        Edward Jones Guided Solutions
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/wealth-strategies/client-consultation-group"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Client Consultation Group"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link3"
                                        id="anchor-link-43">
                                        Client Consultation Group
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <a
                                    className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group heading-5 border-gray-300"
                                    href="/us-en/investment-services/investment-products"
                                    data-component-name=""
                                    rel=""
                                    target="_self"
                                    data-gtm-category="site nav"
                                    data-gtm-action="header:level 2"
                                    data-gtm-label="Investment Products"
                                    heading=""
                                    body=""
                                    classexternallink=""
                                    data-testid="l2link4"
                                    id="anchor-link-44">
                                    Investment Products
                                  </a>
                                  <ul>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/investment-products/how-do-stocks-work"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Stock Selection"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link1"
                                        id="anchor-link-45">
                                        Stock Selection
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/investment-products/fixed-income-investments"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Bonds, CDs and other Fixed-Income Investments"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link2"
                                        id="anchor-link-46">
                                        Bonds, CDs and other Fixed-Income
                                        Investments
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/investment-products/what-are-mutual-funds"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Mutual Funds"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link3"
                                        id="anchor-link-47">
                                        Mutual Funds
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/investment-products/what-are-etfs-how-they-work"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label=" Exchange-traded Funds"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link4"
                                        id="anchor-link-48">
                                        Exchange-traded Funds
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/investment-products/unit-investment-trust"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Unit Investment Trusts"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link5"
                                        id="anchor-link-49">
                                        Unit Investment Trusts
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/investment-products/donor-advised-funds"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Donor Advised Funds"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link6"
                                        id="anchor-link-50">
                                        Donor Advised Funds
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <a
                                    className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group heading-5 border-gray-300"
                                    href="/us-en/investment-services/trust-company"
                                    data-component-name=""
                                    rel=""
                                    target="_self"
                                    data-gtm-category="site nav"
                                    data-gtm-action="header:level 2"
                                    data-gtm-label="Trust Company"
                                    heading=""
                                    body=""
                                    classexternallink=""
                                    data-testid="l2link5"
                                    id="anchor-link-51">
                                    Trust Company
                                  </a>
                                  <ul>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/trust-company/common-estate-planning-questions"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Common Questions About Estate Planning"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link1"
                                        id="anchor-link-52">
                                        Common Questions About Estate Planning
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/trust-company/estate-planning"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Estate Planning"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link2"
                                        id="anchor-link-53">
                                        Estate Planning
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/trust-company/disclosures"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Trust Company Disclosures"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link3"
                                        id="anchor-link-54">
                                        Trust Company Disclosures
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center focus:ring focus:border-blue-300 group link-with-new-window"
                                        href="https://edwardjonestrustcompany.ecx.seic.com/"
                                        data-component-name=""
                                        rel=""
                                        target="_blank"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="View My Trust Account"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink="link-with-new-window"
                                        data-testid="l3link4">
                                        View My Trust Account
                                        <span className="sr-only">
                                          opens in a new window
                                        </span>
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <a
                                    className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group heading-5 border-gray-300"
                                    href="/us-en/investment-services/insurance"
                                    data-component-name=""
                                    rel=""
                                    target="_self"
                                    data-gtm-category="site nav"
                                    data-gtm-action="header:level 2"
                                    data-gtm-label="Insurance and Annuities"
                                    heading=""
                                    body=""
                                    classexternallink=""
                                    data-testid="l2link6"
                                    id="anchor-link-55">
                                    Insurance and Annuities
                                  </a>
                                  <ul>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/insurance/permanent-life-insurance"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Permanent Life Insurance"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link1"
                                        id="anchor-link-56">
                                        Permanent Life Insurance
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/insurance/term-life-insurance"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Term Life Insurance"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link2"
                                        id="anchor-link-57">
                                        Term Life Insurance
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/insurance/long-term-disability-insurance"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Long-term Disability Insurance"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link3"
                                        id="anchor-link-58">
                                        Long-term Disability Insurance
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/insurance/long-term-care-insurance"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Long-term Care Insurance"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link4"
                                        id="anchor-link-59">
                                        Long-term Care Insurance
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/insurance/what-annuity"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Annuities"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link5"
                                        id="anchor-link-60">
                                        Annuities
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <a
                                    className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group heading-5 border-gray-300"
                                    href="/us-en/investment-services/business-owners"
                                    data-component-name=""
                                    rel=""
                                    target="_self"
                                    data-gtm-category="site nav"
                                    data-gtm-action="header:level 2"
                                    data-gtm-label="Solutions For Business Owners"
                                    heading=""
                                    body=""
                                    classexternallink=""
                                    data-testid="l2link7"
                                    id="anchor-link-61">
                                    Solutions For Business Owners
                                  </a>
                                  <ul>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/business-owners/sep-ira"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="SEP IRAs"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link1"
                                        id="anchor-link-62">
                                        SEP IRAs
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/business-owners/owner-only-401k"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Owner-only 401(k) Plan"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link2"
                                        id="anchor-link-63">
                                        Owner-only 401(k) Plan
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/business-owners/owner-only-defined-benefit-plan"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Owner-only Defined Benefit Plan"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link3"
                                        id="anchor-link-64">
                                        Owner-only Defined Benefit Plan
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/business-owners/retirement-plans-employees"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Plans for Your Employees"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link4"
                                        id="anchor-link-65">
                                        Plans for Your Employees
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/investment-services/business-owners/business-credit-cards"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Business Credit Cards"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link5"
                                        id="anchor-link-66">
                                        Business Credit Cards
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <a
                                    className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group heading-5 border-gray-300"
                                    href="/us-en/client-resource-center"
                                    data-component-name=""
                                    rel=""
                                    target="_self"
                                    data-gtm-category="site nav"
                                    data-gtm-action="header:level 2"
                                    data-gtm-label="Client Resource Center"
                                    heading=""
                                    body=""
                                    classexternallink=""
                                    data-testid="l2link8"
                                    id="anchor-link-67">
                                    Client Resource Center
                                  </a>
                                  <ul>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/client-resource-center/online-access-account-features"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Online Access and Account Features"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link1"
                                        id="anchor-link-68">
                                        Online Access and Account Features
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/client-resource-center/tax-statements-resources"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Tax Resources and Statements"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link2"
                                        id="anchor-link-69">
                                        Tax Resources and Statements
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/client-resource-center/newsletter"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Edward Jones Perspective Newsletter"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link3"
                                        id="anchor-link-70">
                                        Edward Jones Perspective Newsletter
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/client-resource-center/emergency-contact-info"
                                        data-component-name=""
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Contacting Us in an Emergency"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link4"
                                        id="anchor-link-71">
                                        Contacting Us in an Emergency
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <button
                          className="nav-tabs-item h-12 px-1 mx-2 text-sm leading-tight border-transparent button-tracking"
                          type="button"
                          aria-expanded="false"
                          data-gtm-category="site nav"
                          data-gtm-action="header:level 1"
                          data-testid="l1link4"
                          data-gtm-label="Market News and Insights">
                          Market News and Insights
                        </button>
                        <div
                          className="absolute left-0 right-0 z-40 focus:outline-none"
                          hidden="">
                          <div className="bg-white shadow-xl pt-12 pb-16">
                            <div className="container">
                              <div
                                className="heading-4 text-2xl"
                                data-testid="tab4heading">
                                Market News and Insights
                              </div>
                              <p
                                className="text-lg text-gray-500 mb-8"
                                data-testid="tab4para">
                                Market news and financial tools to help you stay
                                informed and on track.
                              </p>
                              <ul className="mega-menu-container text-sm flex">
                                <li className="w-1/4">
                                  <a
                                    className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group heading-5 border-gray-300"
                                    href="/us-en/market-news-insights/stock-market-news"
                                    rel=""
                                    target="_self"
                                    data-gtm-category="site nav"
                                    data-gtm-action="header:level 2"
                                    data-gtm-label="Stock Market News"
                                    heading=""
                                    body=""
                                    classexternallink=""
                                    data-testid="l2link1">
                                    Stock Market News
                                  </a>
                                  <ul>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/market-news-insights/stock-market-news/daily-market-recap"
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Daily Market Snapshot"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link1">
                                        Daily Market Snapshot
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/market-news-insights/stock-market-news/stock-market-weekly-update"
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Weekly Market Wrap"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link2">
                                        Weekly Market Wrap
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/market-news-insights/stock-market-news/monthly-market-compass"
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Monthly Market Compass"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link3">
                                        Monthly Market Compass
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/market-news-insights/stock-market-news/investment-portfolio-management"
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Monthly Portfolio Brief"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link4">
                                        Monthly Portfolio Brief
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/market-news-insights/stock-market-news/market-pulse"
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Our Market Pulse"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link5">
                                        Our Market Pulse
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/market-news-insights/stock-market-news/quarterly-market-outlook"
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Quarterly Market Outlook"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link6">
                                        Quarterly Market Outlook
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/market-news-insights/stock-market-news/annual-market-outlook"
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="2023 Outlook"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link7">
                                        2023 Outlook
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/market-news-insights/stock-market-news/get-quote"
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Get a Stock Quote"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link8">
                                        Get a Stock Quote
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group"
                                        href="/us-en/market-news-insights/stock-market-news/current-rates"
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Current Rates"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link9">
                                        Current Rates
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                                <div className="w-1/4">
                                  <li>
                                    <a
                                      className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group heading-5"
                                      href="/us-en/market-news-insights/financial-calculators"
                                      rel=""
                                      target="_self"
                                      data-gtm-category="site nav"
                                      data-gtm-action="header:level 2"
                                      data-gtm-label="Financial Calculators"
                                      heading=""
                                      body=""
                                      classexternallink=""
                                      data-testid="l2link4">
                                      Financial Calculators
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group heading-5"
                                      href="/us-en/market-news-insights/guidance-perspective"
                                      rel=""
                                      target="_self"
                                      data-gtm-category="site nav"
                                      data-gtm-action="header:level 2"
                                      data-gtm-label="Guidance and Perspectives"
                                      heading=""
                                      body=""
                                      classexternallink=""
                                      data-testid="l2link8">
                                      Guidance and Perspectives
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group heading-5"
                                      href="/us-en/market-news-insights/investor-education"
                                      rel=""
                                      target="_self"
                                      data-gtm-category="site nav"
                                      data-gtm-action="header:level 2"
                                      data-gtm-label="Investor Education"
                                      heading=""
                                      body=""
                                      classexternallink=""
                                      data-testid="l2link5">
                                      Investor Education
                                    </a>
                                  </li>
                                </div>
                                <div className="w-1/4">
                                  <li>
                                    <a
                                      className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group heading-5"
                                      href="/us-en/market-news-insights/personal-finance"
                                      rel=""
                                      target="_self"
                                      data-gtm-category="site nav"
                                      data-gtm-action="header:level 2"
                                      data-gtm-label="Personal Finance"
                                      heading=""
                                      body=""
                                      classexternallink=""
                                      data-testid="l2link6">
                                      Personal Finance
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group heading-5"
                                      href="/us-en/market-news-insights/retirement"
                                      rel=""
                                      target="_self"
                                      data-gtm-category="site nav"
                                      data-gtm-action="header:level 2"
                                      data-gtm-label="Planning for Retirement"
                                      heading=""
                                      body=""
                                      classexternallink=""
                                      data-testid="l2link2">
                                      Planning for Retirement
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group heading-5"
                                      href="/us-en/market-news-insights/retirement-social-security"
                                      rel=""
                                      target="_self"
                                      data-gtm-category="site nav"
                                      data-gtm-action="header:level 2"
                                      data-gtm-label="Being Retired and Understanding Social Security"
                                      heading=""
                                      body=""
                                      classexternallink=""
                                      data-testid="l2link3">
                                      Social Security and Retirement
                                    </a>
                                  </li>
                                </div>
                                <li className="w-1/4">
                                  <a
                                    className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group heading-5"
                                    href="/us-en/market-news-insights/taxes-and-investing"
                                    rel=""
                                    target="_self"
                                    data-gtm-category="site nav"
                                    data-gtm-action="header:level 2"
                                    data-gtm-label="Taxes and Investing"
                                    heading=""
                                    body=""
                                    classexternallink=""
                                    data-testid="l2link7">
                                    Taxes
                                  </a>
                                  <ul>
                                    <li>
                                      <a
                                        className="mega-menu-link flex items-center justify-between focus:ring focus:border-blue-300 group heading-5"
                                        href="/us-en/market-news-insights/webinars"
                                        rel=""
                                        target="_self"
                                        data-gtm-category="site nav"
                                        data-gtm-action="header:level 2"
                                        data-gtm-label="Webinars"
                                        icon="[object Object]"
                                        heading=""
                                        body=""
                                        items=""
                                        classexternallink=""
                                        data-testid="l3link1">
                                        Webinars
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div id="personalization-Header-Buttons">
                    <div className="flex items-center space-x-2 md:space-x-3 flex-shrink-0">
                      <a
                        className="js-subdomain-link text-sm items-center whitespace-nowrap btn btn-secondary px-3.5 cursor-pointer"
                        tabIndex={0}
                        data-gtm-category="site nav"
                        data-gtm-action="eyebrow"
                        data-gtm-label="login"
                        data-testid="login-2"
                        href="https://accountaccess.edwardjones.com/ca-logon/logon.action"
                        target="_blank"
                        variation={2}>
                        <span className="md:leading-tight lg:w-auto">
                          Secure Login
                        </span>
                      </a>
                      <span>
                        <a
                          className="btn find-fa-nav-btn btn-primary lg:mr-0 px-3 lg:px-5 text-sm"
                          tabIndex={0}
                          data-gtm-category="site nav"
                          data-gtm-action="header:level 1"
                          data-gtm-label="fa locator"
                          data-testid="fa-1"
                          data-component-name="Navigation_l1_fa_redirect"
                          href="/us-en/search/financial-advisor/results"
                          id="anchor-link-115">
                          Find a Financial Advisor
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="mobile-header"
                className="flex items-center justify-between space-x-4 h-16 lg:h-12 lg:mt-3 ej -true">
                <div
                  id="branding"
                  className="inline-flex h-16 lg:h-12 -ml-6 lg:m-0 flex-shrink-0 bg-yellow-500"
                  aria-hidden="false">
                  <a
                    className="flex flex-shrink-0 items-center px-4 first-access"
                    data-gtm-category="site nav"
                    data-gtm-action="header:level 1"
                    data-gtm-label="logo"
                    href="https://www.edwardjones.com/us-en"
                    id="anchor-link-2">
                    <img
                      alt="Edward Jones"
                      className="block w-24 lg:w-32"
                      src="https://www.edwardjones.com/sites/default/files/2021-01/logo-gray.svg"
                      loading="lazy"
                    />
                  </a>
                </div>
                <div id="rightSection" className="md:flex" aria-hidden="false">
                  <div className="lg:flex-1 main-links-wrapper false false ej" />
                  <div id="personalization-Header-Buttons">
                    <div className="flex items-center space-x-2 md:space-x-3 flex-shrink-0 ">
                      <button
                        id="search-toggle"
                        data-gtm-category="site nav"
                        data-gtm-action="eyebrow"
                        data-gtm-label="search"
                        data-testid="search-2"
                        aria-label="search Edward Jones"
                        className="w-11 h-11 md:h-10 md:w-10 flex-shrink-0 rounded-full lg:mx-5 md:flex items-center justify-center flex z-40 border-transparent button-tracking"
                        aria-expanded="false"
                        aria-controls="search-dialog">
                        <i
                          className="block w-5 h-5 pointer-events-none"
                          aria-hidden="true">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24">
                            <defs />
                            <path d="M9.99 1.567a8.422 8.422 0 016.462 13.824l5.443 5.444a.75.75 0 11-1.06 1.06l-5.444-5.443A8.422 8.422 0 119.99 1.567zm0 1.5a6.922 6.922 0 104.884 11.828l.01-.012a6.897 6.897 0 002.028-4.894A6.922 6.922 0 009.99 3.067z" />
                          </svg>
                        </i>
                        <span className="sr-only"> </span>
                      </button>
                      <a
                        className="js-subdomain-link text-sm items-center whitespace-nowrap btn btn-secondary px-3.5 cursor-pointer z-40"
                        tabIndex={0}
                        data-gtm-category="site nav"
                        data-gtm-action="eyebrow"
                        data-gtm-label="login"
                        data-testid="login-2"
                        href="https://onlineaccess.edwardjones.com/app/oa-login"
                        target="_blank"
                        id="personalization-header-secure-login-btn"
                        variation={2}>
                        <span className="md:leading-tight lg:w-auto">
                          Secure Login
                        </span>
                      </a>
                      <button
                        className="menu-toggle flex items-center lg:hidden pl-3 z-50 button-tracking"
                        type="button"
                        data-gtm-category="site nav"
                        data-gtm-action="eyebrow"
                        data-gtm-label="cross"
                        data-testid="hamburger"
                        aria-expanded="false">
                        <div id="hamburger" className="w-6 h-5 mt-1 ">
                          <span className="bg-gray-500" />
                          <span className="bg-gray-500" />
                          <span className="bg-gray-500" />
                        </div>
                        <span className="sr-only">close</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </header>
        </div>
      </div>
    );
  }
}
