import React from "react";


const RichTextEditor = ({item}) => {
    return (
        <div key={item.id}>
            <section className="container w-full lg:px-28 mt-10 mb-10">
                <div className="rich-text" dangerouslySetInnerHTML={{__html: item.attributes.field_rich_text_box.value}} /> 
            </section>
        </div>
      )
  }

export default RichTextEditor