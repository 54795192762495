import React, { useState } from "react";

const AccordionItem = ({ i, accordionBody, index }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <h3 class="heading-5">
        <button
          onClick={handleToggle}
          aria-expanded="false"
          aria-controls={i.data.attributes.field_aria_controls_id}
          id={`accordion${i.data.attributes.field_accordion_id}`}
          class="accordion-trigger w-full py-5 border-b border-gray-500 flex justify-between button-tracking"
          data-gtm-category="accordion"
          data-gtm-action="click"
          data-gtm-label={`title:${i.data.attributes.field_accordion_heading_text}`}>
          <span class="text-left w-11/12">
            {i.data.attributes.field_accordion_heading_text}
          </span>
          <i class="pointer-events-none inline-block mr-2">
            {isOpen ? (
              <svg
                width={13}
                height={13}
                version="1.1"
                id={`${i.data.attributes.field_accordion_id}-icon`}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 13 13"
                style={{ enableBackground: "new 0 0 13 13" }}
                xmlSpace="preserve">
                <g>
                  <path
                    id="a_00000015318682021922682640000011787242700526423214_"
                    class="st0"
                    d="M1.8,12.3l4.5-4.5l4.5,4.5l1.5-1.5l-6-6l-6,6L1.8,12.3z"
                  />
                </g>
              </svg>
            ) : (
              <svg
                width={13}
                height={13}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink">
                <defs>
                  <path
                    id={`${i.data.attributes.field_accordion_id}-icon`}
                    d="M2.121 0v6.363l6.364.001v2.121H0V0z"></path>
                </defs>
                <g
                  transform="scale(-1 1) rotate(-45 1.485 18.071)"
                  fill="none"
                  fill-rule="evenodd">
                  <mask id="b" fill="#fff">
                    <use
                      xlinkHref={`#${i.data.attributes.field_accordion_id}-icon`}></use>
                  </mask>
                  <g mask="url(#b)">
                    <path
                      fill="#3B3D3F"
                      fill-rule="nonzero"
                      d="M6.364-13.757l18 18-18 18-18-18z"></path>
                  </g>
                </g>
              </svg>
            )}
          </i>
        </button>
      </h3>
      {isOpen && (
        <div
          id={i.data.attributes.field_accordion_id}
          role="region"
          aria-labelledby={`accordion${i.data.attributes.field_accordion_id}`}
          class="px-5 py-8 text-lg border-b border-gray-500 space-y-10"
          hidden="">
          <section class="w-full lg:px-0">
            <div
              class="rich-text"
              dangerouslySetInnerHTML={{
                __html:
                  accordionBody[index].data[0].attributes.field_rich_text_box
                    .value,
              }}
            />
          </section>
        </div>
      )}
    </div>
  );
};

export default AccordionItem;
