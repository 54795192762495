import { useState, useEffect } from "react";
import axios from "axios";

function DataFetching() {
  const [hero, sethero] = useState(null);

  useEffect(() => {
    const loadhero = async () => {
      const response = await axios.get("https://web-dev02.ac.edwardjones.com/us-en/jsonapi/node/landing_page/5e95e871-6827-4a2d-a2bd-41285f18bb91/field_hero");
      sethero(response.data);
    };
    loadhero();
  }, []);


if(!hero) return <div></div>
  return (
<article className="responsive-grid row-gap-0 items-center">
        <div data-widget="leadgen-popup" />
        <div className="row-start-1 col-start-1 col-end-5 md:col-start-2 md:col-end-9 lg:col-start-5 lg:col-end-13"> 
            <picture> 
                <source media="(orientation: portrait)" srcSet={hero.data.relationships.field_hero_desk_tablet_img.data.meta.asset_path} /> 
                <source media="(orientation: landscape) and (min-width: 900px)" srcSet={hero.data.relationships.field_hero_desk_tablet_img.data.meta.asset_path} /> 
                <img className="w-full" src={hero.data.relationships.field_hero_desk_tablet_img.data.meta.asset_path} alt=" Main Hero" /> 
            </picture>
        </div>
        <div className=" row-start-2 lg:row-start-1 col-start-1 col-end-5 md:col-end-7 -mt-20 mr-6 lg:m-0 space-y-5 bg-gray-100 p-8 md:p-15 lg:py-14 lg:pl-16 lg:pr-20 lg:space-y-2">
            <h1 className="leading-tight heading-2 md:heading-1">{hero.data.attributes.field_hero_title}</h1>
            <div className="pb-2 lg:pb-4 text-xl rich-text">
                <p>{hero.data.attributes.field_hero_description}</p>
            </div>
            <a data-gtm-category="news" data-gtm-action="hero click" data-gtm-label="headless drupal page:click here:primary" className="js-redirect hero-cta-link text-lg" href={hero.data.attributes.field_hero_cta_link.url} data-prop-ctatitle={hero.data.attributes.field_hero_cta_link.title} data-prop-heading={hero.data.attributes.field_hero_title} id="anchor-link-1941">
                <span className="block btn btn-warning animate-btn animate-btn-warning md:inline-block md:w-80 px-0"> {hero.data.attributes.field_hero_cta_link.title} </span>
            </a>
            <a data-gtm-category="news" data-gtm-action="hero click" data-gtm-label="Learn More:secondary" className="js-redirect hero-cta-link text-lg" href={hero.data.attributes.field_main_hero_secondary_cta.url} data-prop-ctatitle="Learn More" data-prop-heading="Headless Drupal Page" id="anchor-link-1942">
                <span className="block btn btn-primary animate-btn animate-btn-primary md:inline-block mt-3 md:w-80 px-0"> {hero.data.attributes.field_main_hero_secondary_cta.title} </span>
            </a>
        </div>
    </article>
  );
}
export default DataFetching;