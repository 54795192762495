import React, { useEffect, useState } from "react";

const CustomFaGrouping = ({ item }) => {
  const [data, setData] = useState([]);
  const [body, setBody] = useState([]);

  const fetchData = async (urlVal) => {
    try {
      const response = await fetch(urlVal);
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchAllData = async (urls, body = false) => {
    try {
      const results = await Promise.all(urls.map((url) => fetchData(url)));
      const combinedData = [].concat(...results);
      if (body === true) {
        setBody(combinedData);
      } else setData(combinedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const urlFun = (val1, val2, id) =>
    `https://web-dev02.ac.edwardjones.com/us-en/jsonapi/${val1}/${val2}/${id}`;

  const createUrl = (url) => {
    const type = url.type.split("--");
    const val1 = type[0];
    const val2 = type[1];
    const id = url.id;
    return { val1, val2, id };
  };

  useEffect(() => {
    const urlArray = item.relationships.field_financial_advisors.data.map(
      (i) => {
        const { val1, val2, id } = createUrl(i);
        const url = urlFun(val1, val2, id);
        return url;
      }
    );
    fetchAllData(urlArray);
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      const branchArray = data.map((i) => {
        const branchData = i.data.relationships.field_locationid.data;
        const { val1, val2, id } = createUrl(branchData);
        const url = urlFun(val1, val2, id);
        return url;
      });
      fetchAllData(branchArray, true);
    }
  }, [data]);

  const formatPhoneNo = (phoneNumber) =>
    `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6)}`;

  return (
    <div data-widget="fa-custom-groupings" class="container">
      <section>
        <ul class="grid gap-6 md:grid-cols-1 lg:gap-5 lg:grid-cols-3">
          {data.length &&
            body.length &&
            data.map((i, index) => {
              return (
                <li class="card border-2 hover:border-gray-500">
                  <div class="card-body">
                    <div class="flex">
                      <div class="w-24 h-36 md:w-36 md:h-48 mr-5">
                        <a
                          class="inline-flex items-center font-bold hover:underline rounded"
                          data-gtm-category="fa contact card"
                          data-gtm-action="click"
                          data-gtm-label="Tyler Schmidt:1815355:fa profile"
                          data-prop-faname={
                            i.data.attributes.field_convertedname
                          }
                          data-prop-faentityid="1815355"
                          data-prop-city=""
                          data-prop-state=""
                          data-prop-country=""
                          data-prop-zipcode=""
                          href="#s"
                          id="anchor-link-2165">
                          <picture>
                            <source
                              media="(max-width:668px)"
                              srcset={i.data.attributes.field_faphoto_imageurl}
                            />
                            <source
                              media="(max-width:1023px)"
                              srcset={i.data.attributes.field_faphoto_imageurl}
                            />
                            <img
                              class="thumb-img w-24 h-36 md:w-36 md:h-48"
                              loading="lazy"
                              data-testid="fa-image-580036"
                              src={i.data.attributes.field_faphoto_imageurl}
                              alt={i.data.attributes.field_convertedname}
                            />
                          </picture>
                        </a>
                      </div>
                      <div class="flex flex-col">
                        <h3
                          data-testid="fa-name-580036"
                          class="heading-5 mt-2 md:text-xl md:mt-0"
                          id="580036">
                          {i.data.attributes.field_convertedname}
                        </h3>
                        <div
                          data-testid="fa-certification-580036"
                          class="md:text-lg uppercase"></div>
                        <address
                          class="block leading-tight mt-auto md:mt-6"
                          data-testid="fa-address-580036">
                          {
                            body[index].data.attributes
                              .field_branchaddresses_address1.address_line1
                          }
                          <br />
                          {
                            body[index].data.attributes
                              .field_branchaddresses_address1.locality
                          }
                          ,{" "}
                          {
                            body[index].data.attributes
                              .field_branchaddresses_address1
                              .administrative_area
                          }
                          {
                            body[index].data.attributes
                              .field_branchaddresses_address1.postal_code
                          }
                        </address>
                        <a
                          class="is_telephone fa-phone-link text-blue-500 hover:underline relative z-10"
                          href="tel:5139322716"
                          data-gtm-category="fa contact card"
                          data-gtm-action="click"
                          data-gtm-label="Tyler Schmidt:1815355:call"
                          aria-describedby="580036"
                          data-testid="fa-contact-580036"
                          data-prop-faname="Tyler Schmidt"
                          data-prop-faentityid="1815355"
                          data-prop-city=""
                          data-prop-state=""
                          data-prop-country=""
                          data-prop-zipcode=""
                          id="anchor-link-2166">
                          <span class="sr-only">phone </span>
                          {formatPhoneNo(
                            body[index].data.attributes.field_telephonevoice
                          )}
                        </a>
                      </div>
                    </div>
                    <div class="flex flex-col justify-center items-center md:flex-row md:justify-start mt-8 md:mt-5">
                      <div class="w-full md:w-36 hidden md:block mt-4 md:mt-0">
                        <a
                          class="btn btn-dark block text-lg py-2 hover:bg-gray-400 hover:border-gray-400 stretched-link"
                          data-prop-faname="Tyler Schmidt"
                          data-prop-faentityid="1815355"
                          data-prop-city=""
                          data-prop-state=""
                          data-prop-country=""
                          data-prop-zipcode=""
                          data-testid="fa-link-580036"
                          data-gtm-category="fa contact card"
                          data-gtm-action="click"
                          data-gtm-label="Tyler Schmidt:1815355:fa profile"
                          href={`https://web-dev02.ac.edwardjones.com${i.data.attributes.path.alias}`}
                          aria-describedby="580036"
                          id="anchor-link-2167">
                          View Profile
                        </a>
                      </div>
                      <ul class="hidden md:flex divide-x justify-center leading-none whitespace-nowrap text-center">
                        <li class="px-5">
                          <a
                            class="btn btn-secondary py-2 inline-flex items-center relative"
                            data-gtm-category="fa contact card"
                            data-gtm-action="click"
                            data-gtm-label="Tyler Schmidt:1815355:ask a question"
                            data-prop-faname="Tyler Schmidt"
                            data-prop-faentityid="1815355"
                            data-prop-city=""
                            data-prop-state=""
                            data-prop-country=""
                            data-prop-zipcode=""
                            data-testid="fa-contactlink-580036"
                            href={`https://web-dev02.ac.edwardjones.com${i.data.attributes.path.alias}/contact`}
                            aria-describedby="580036"
                            id="anchor-link-2168">
                            Contact Me
                          </a>
                        </li>
                      </ul>
                      <div class="md:hidden"></div>
                      <div class="w-full md:hidden flex justify-between">
                        <div class="mt-3"></div>
                        <div class="mt-3"></div>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
      </section>
    </div>
  );
};

export default CustomFaGrouping;
