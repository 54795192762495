import { useState, useEffect } from "react";
import axios from "axios";

function DataFetching() {
  const [footerdata, setfooterdata] = useState(null);
  const [marketdata, setmarketdata] = useState(null);

  useEffect(() => {
    const loadfooterdata = async () => {
      const response = await axios.get("https://web-dev02.ac.edwardjones.com/us-en/jsonapi/node/global_footer/572c83b9-ca37-43a3-b7c4-e99bc1110a88");
      setfooterdata(response.data);
      const marketresponse = await axios.get("https://web-dev02.ac.edwardjones.com/get-current-quote/us");
      setmarketdata(marketresponse.data);
    };
    loadfooterdata();
  }, []);

// console.log(footerdata);
if(!marketdata) return <div></div>
    return (
      <footer id="homepage_us_en-footer-sq" className="pt-10 text-gray-100 bg-gray-500 pb-14 global-footer z-20" aria-hidden="false">
      <div className="container">
        <div id="block-globalfooterblock" className="block block-ej-footer block-ej-global-footer-block">
          <div className="content">
            <div id="footer-market-index" data-prop-heading="Market Data" data-prop-endpoint='{"apiEndPoint":"\/get-current-quote\/us"}'>
              <div className="responsive-grid items-baseline border-b pb-5 mb-8 w-full" role="marquee" aria-live="off">
                <div className="col-start-1 col-end-5 lg:col-end-4 py-1 min-w-0">
                  <h2 className="heading-4 truncate">{footerdata.data.attributes.field_market_data_text}</h2>
                </div>
                <ul className=" col-start-1 col-end-5 md:col-end-9 lg:col-start-4 lg:col-end-13 md:flex md:flex-wrap text-lg whitespace-nowrap">
                  {marketdata.result.map(item => <li key={item.symbol} className="flex py-1 w-1/2 md:w-1/3 text-lg whitespace-nowrap"> <h3 className="font-bold mr-2">{item.symbol}</h3> <span className="mr-1">{item.lastTradePrice}</span> <span className="whitespace-nowrap text-green-300"> <i className="inline-block align-text-bottom w-4 h-4"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"> <defs /> <path d="M12 21.98c.6 0 1.09-.48 1.09-1.08V5.47l3.56 3.56c.39.39.99.42 1.41.1l.12-.1c.43-.42.43-1.1 0-1.53l-5.41-5.42a1.06 1.06 0 00-.12-.1l-.04-.02a1.07 1.07 0 00-.08-.05l-.06-.03a1.93 1.93 0 00-.08-.04l-.04-.02a1.78 1.78 0 00-.1-.03h-.05a1.2 1.2 0 00-.2-.02h.1H11.9l-.09.02h-.04l-.1.03-.04.02-.09.03-.06.04-.07.04c-.05.04-.11.08-.16.14l.1-.1-.1.1L5.82 7.5a1.08 1.08 0 101.53 1.53l3.57-3.56V20.9c0 .55.4 1 .94 1.08H12z" /> </svg> </i> ({item.priceChange}) </span> </li>)}
                </ul>
              </div>
            </div>
            <div className="items-baseline responsive-grid footer-parent row-gap-13">
              <nav className="row-start-1 col-start-1 col-end-5 md:col-end-9 lg:col-end-10 -mt-2 lg:-mt-1" aria-label="footer primary links">
                <ul className="md:flex md:flex-wrap md:-mx-6 text-lg">
                {footerdata.data.attributes.field_primary_links.map(item => <li key={item.title} className="my-3 md:w-1/3 md:px-6"><a className={"focus:outline-blue-solid hover:underline " + item.options.attributes.class.join(", ")} data-component-name="footer_eid_fa_search" data-gtm-category="site nav" data-gtm-action="footer" data-gtm-label={item.title} href={item.url} rel="" id="anchor-link-143" >{item.title}</a></li>)}
                </ul>
              </nav>
              <div className="lg:float-right row-start-2 lg:row-start-1 col-start-1 col-end-5 md:col-end-9 lg:col-start-10 lg:col-end-13">
                <div className="mt-5 md:mt-6 lg:mt-auto">
                  <h2 id="social_icon_header" className="text-sm font-semibold mb-5">
                    {footerdata.data.attributes.field_social_media_links.platform_values.social_links_header_text}
                  </h2>
                  <ul className="flex -mx-3 items-center">
                    <li className="px-3">
                      <a data-gtm-category="site nav" data-gtm-action="footer" data-gtm-label="social:facebook" className="w-6 h-6 block focus:outline-blue-solid" href={footerdata.data.attributes.field_social_media_links.platform_values.facebook.value} target="_blank" aria-label={footerdata.data.attributes.field_social_media_links.platform_values.facebook.alt_attribute}>
                       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                          <path d="M24 12a12 12 0 10-13.9 11.9v-8.4h-3V12h3V9.4c0-3 1.8-4.7 4.6-4.7l2.6.2v3h-1.5c-1.5 0-2 .9-2 1.8V12h3.4l-.5 3.5h-2.8v8.4A12 12 0 0024 12z" />{" "}
                        </svg>
                      </a>
                    </li>
                    <li className="px-3">
                      <a data-gtm-category="site nav" data-gtm-action="footer" data-gtm-label="social:twitter" className="w-6 h-6 block focus:outline-blue-solid" href={footerdata.data.attributes.field_social_media_links.platform_values.linkedin.value} target="_blank" aria-label={footerdata.data.attributes.field_social_media_links.platform_values.linkedin.alt_attribute}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                          <path d="M23.95 4.57a10 10 0 01-2.82.77 4.96 4.96 0 002.16-2.72c-.95.56-2 .96-3.12 1.19a4.92 4.92 0 00-8.38 4.48A13.93 13.93 0 011.63 3.16a4.92 4.92 0 001.52 6.57 4.9 4.9 0 01-2.23-.61v.06c0 2.38 1.7 4.37 3.95 4.82a5 5 0 01-2.21.09 4.94 4.94 0 004.6 3.42A9.87 9.87 0 010 19.54a14 14 0 007.56 2.21c9.05 0 14-7.5 14-13.98 0-.21 0-.42-.02-.63A9.94 9.94 0 0024 4.59l-.05-.02z" />
                        </svg>
                      </a>
                    </li>
                    <li className="px-3"><a data-gtm-category="site nav" data-gtm-action="footer" data-gtm-label="social:linkedin" className="w-6 h-6 block focus:outline-blue-solid" href={footerdata.data.attributes.field_social_media_links.platform_values.twitter.value} target="_blank" aria-label={footerdata.data.attributes.field_social_media_links.platform_values.twitter.alt_attribute}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                          <path d="M20.4 20.5H17v-5.6c0-1.3 0-3-1.9-3-1.8 0-2 1.4-2 2.9v5.7H9.3V9h3.4v1.6c.5-1 1.6-1.9 3.4-1.9 3.6 0 4.2 2.4 4.2 5.5v6.3zm-15-13a2 2 0 110-4.2 2 2 0 010 4.1zm1.7 13H3.6V9H7v11.5zM22.2 0H1.8C.8 0 0 .8 0 1.7v20.6c0 1 .8 1.7 1.8 1.7h20.4c1 0 1.8-.8 1.8-1.7V1.7c0-1-.8-1.7-1.8-1.7z" />{" "}
                        </svg>
                      </a>
                    </li>
                    <li className="px-3"><a data-gtm-category="site nav" data-gtm-action="footer" data-gtm-label="social:youtube" className="w-6 h-6 block focus:outline-blue-solid" href={footerdata.data.attributes.field_social_media_links.platform_values.youtube.value} target="_blank" aria-label={footerdata.data.attributes.field_social_media_links.platform_values.youtube.alt_attribute}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                          <path fillRule="nonzero" d="M23.5 6.2a3 3 0 00-2.1-2.08c-1.86-.5-9.39-.5-9.39-.5s-7.5-.01-9.4.5A3 3 0 00.53 6.2 31.25 31.25 0 000 12.02a31.25 31.25 0 00.52 5.78 3 3 0 002.08 2.1c1.87.5 9.4.5 9.4.5s7.5 0 9.4-.5a3 3 0 002.09-2.1 31.25 31.25 0 00.5-5.78 31.25 31.25 0 00-.5-5.8zM9.6 15.6V8.4l6.27 3.61z"/>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <nav className="row-start-3 lg:row-start-2 col-start-1 col-end-5 md:col-end-9 lg:col-end-13 overflow-hidden mt-6 md:mt-8 lg:mt-2" aria-label="footer secondary links">
                <ul className="-mx-4 leading-snug text-sm md:flex md:flex-wrap md:divide-x" id="secondarylink">
                  {footerdata.data.attributes.field_secondary_links.map(item =><li className="my-3 border-r-2"><a data-gtm-category="site nav" data-gtm-action="footer" data-gtm-label={item.title} className="px-4 hover:underline focus:outline-blue-solid" href={item.url} rel="" id="anchor-link-147">{item.title}</a></li>)}
                </ul>
              </nav>
              <div className="row-start-4 lg:row-start-3 col-start-1 col-end-5 md:col-end-9 lg:col-end-13 mt-7 lg:mt-8">
                <ul className="flex -mx-2 flex-wrap items-center md:space-x-12 lg:space-x-10 space-y-20 md:space-y-4 lg:space-y-0 lg:pb-4">
                  <li className="md:w-40 lg:w-56 ml-2">
                    {" "}
                    <a href="https://brokercheck.finra.org/" target='"_blank"'>
                      {" "}
                      <img
                        src="https://web-dev02.ac.edwardjones.com/sites/default/files/acquiadam/2020-09/brokercheck-by-finra-vector-logo%202%402x.png"
                        alt="BrokerCheck by FINRA"
                        loading="lazy"
                      />{" "}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="row-start-5 lg:row-start-4 col-start-1 col-end-5 md:col-end-9 text-sm leading-snug lg:text-xs mt-8 md:mt-10 lg:mt-6">
                <div className="rich-text footer-disclaimers focus:footer-discliamer-outline text-gray-300" dangerouslySetInnerHTML={{__html: footerdata.data.attributes.field_disclaimers.processed}} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
      )
    }
      export default DataFetching;
