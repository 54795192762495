/* eslint-disable jsx-a11y/iframe-has-title */
import styles from "mystyle.css";
import React from "react";
import Hero from "./Hero.js";
import Content from "./Content.js";
import Header from "./Header.js";
import Footer from "./Footer.js";
import ChatBot from "components/molecules/chat-bot";

export default function App() {
  document.title = "Edwardjones | Headless Drupal";
  const link = document.querySelector('link[rel="icon"]');
  link.setAttribute("href", "/images/favicon.ico");
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="relative container mb-14 px-0 md:px-6 mt-0 md:mt-6 lg:px-0">
        <Hero />
      </div>
      <div className="space-y-10 lg:space-y-20">
        <Content />
        <ChatBot />
        <Footer />
      </div>
    </div>
  );
}
