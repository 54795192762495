import React from "react";


const ChartImages = ({item}) => {
    return (
        <div key={item.id} data-gtm-category="chart" data-gtm-action="click" data-gtm-label={item.relationships.field_chart_images.data.id} data-quickedit-entity-id="paragraph/35081747" className="paragraph w-full container">
        <div>
        <div className="image-component-enhanced mx-auto" data-testid="image-component-enhanced">
        <picture>
          <source media="(orientation: portrait)" srcSet={item.relationships.field_chart_images.data.meta.asset_path} alt={item.attributes.field_asset_alt_text} />
          <source media="(orientation: landscape) and (min-width: 900px)" srcSet={item.relationships.field_chart_images.data.meta.asset_path} alt={item.attributes.field_asset_alt_text} />
          <img className="mb-0.5" src={item.relationships.field_chart_images.data.meta.asset_path} alt={item.attributes.field_asset_alt_text}  />
        </picture>
        <i className="source text-gray-500 font-semibold italic block mt-2.5 text-sm">{item.attributes.field_image_source}</i>
        <button className="accordion-trigger w-full flex items-center h-10 button-tracking" aria-expanded="true" aria-controls="panel-35081747" id="expand-image-description-35081747" data-testid="more-info">
          <span className="text-left text-lg font-semibold text-blue-500 pl-2"> {item.attributes.field_accordion_label}</span>
          <i className="pointer-events-none h-5 w-5 text-blue-500 ml-1 mt-1" aria-hidden="true"><svg width={13} height={13} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 13 13" style={{enableBackground: 'new 0 0 13 13'}} xmlSpace="preserve">
            <g>
              <path id="a_00000015318682021922682640000011787242700526423214_" className="st0" d="M1.8,12.3l4.5-4.5l4.5,4.5l1.5-1.5l-6-6l-6,6L1.8,12.3z" />
            </g>
            </svg>
          </i>
        </button>
        <div aria-labelledby="expand-image-description-35081747" className="accordion-panel p-2 pt-0" id="panel-35081747" role="region">
          <p>{item.attributes.field_image_description}</p>
        </div>
        <div className="pic-blue image-component-border" /></div>
      </div>
    </div>
      )
  }

export default ChartImages