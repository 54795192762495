import React, { useEffect, useState } from "react";

const SideBySideVideo = ({item}) => {
  // const uriId = item.attributes.field_video_transcript_link_labe.uri.split('/')
  // const videoId = uriId[1]

  const [data, setData] = useState([]);
  const [videoId,setVideoId] = useState("")

  const fetchData = async (urlVal) => {
    try {
      const response = await fetch(urlVal);
      const jsonData = await response.json();
      setData(jsonData.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if(item.relationships){
      const videoType=  item.relationships.field_video_side_video.data
          const type= videoType.type.split('--')
          const val1 = type[0]
          const val2 = type[1]
          const id = videoType.id
          const url = `https://web-dev02.ac.edwardjones.com/us-en/jsonapi/${val1}/${val2}/${id}`
      fetchData(url)
    }
    }, []);

    useEffect (()=>{
      if(data?.attributes?.field_media_video_embed_field) {
       const parts = data?.attributes?.field_media_video_embed_field.split('/')
       const id = parts[parts.length - 1];
        setVideoId(id)
      }
    },[data])

  
  return (
        <div class="container my-4 space-y-2 lg:space-y-0 responsive-grid relative px-0 my-0 md:px-0 md:pb-0 mt-0 mb-0"  data-testid="side-by-side-video">
          <div class="col-start-1 col-end-5 md:col-end-13 lg:col-end-8 pr-0">
            <div class="bg-yellow-100 py-12 pl-5 pr-5 lg:py-28 lg:pl-16 lg:pr-32 mr-5 lg:mr-0">
              <h3 class="heading-3" data-testid="sbs-video-heading" id="sbs-heading"> {item.attributes.field_heading_text_side_video}</h3>
              <div class="text-lg min-h-24 leading-6 lg:pr-6" data-testid="sbs-video-sub-heading">
                <p className="mt-5">{item.attributes.field_sub_heading_text_side_vid}</p>
              </div>
    
              <div className="mt-10">
                <a data-testid="sbs-video-cta" className="sbs-cta btn btn-primary lg:mr-0 lg:px-10 text-lg w-full lg:w-auto"
                 href="#" aria-label={item.attributes.field__cta_target_url_side_video.title}> {item.attributes.field__cta_target_url_side_video.title}</a>
              </div>
            </div>
          </div>
    
          <div className="col-start-1 col-end-5 md:col-end-13 -mt-16 lg:absolute lg:col-start-7  lg:h-full lg:w-full lg:flex lg:flex-col lg:justify-center">
            <div className="lg:relative lg:top-4 lg:right-2">
              <div class="relative max-w-full h-0 sbs-video-container w-full overflow-hidden">
                <iframe data-testid="sbs-video-media" class="absolute top-0 left-0 w-full h-full" title="Purpose, inclusion and citizenship message from Managing Partner, Penny Pennington" 
                src={`https://player.vimeo.com/video/${videoId}`} 
                width="100%" height="100%" frameborder="0" allowfullscreen="" data-vimeo-tracked="true" data-ready="true">
                </iframe>
              </div>
              <div class="rich-text mt-2 text-left lg:text-right px-0 text-lg sbs-transcript-container">
                <a data-testid="sbs-video-transcript" rel="" className="" href="/us-en/accessibility-policy/purpose-and-inclusion-citizenship-message-penny-pennington-transcript" aria-label="opens in a new tab">
                  <span>{item.attributes.field_video_transcript_link_labe.title}</span>
                  <i aria-hidden="true" class="ml-1 w-4 h-4 inline-block"> <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor"> <path fill-rule="evenodd" d="M11.38 5.62a.75.75 0 110 1.5h-7.5a.5.5 0 00-.5.5v12.5a.5.5 0 00.5.5h12.5a.5.5 0 00.5-.5v-7.5a.75.75 0 011.5 0v7.5a2 2 0 01-2 2H3.88a2 2 0 01-2-2V7.62a2 2 0 012-2zm10-3.75a.77.77 0 01.42.13.75.75 0 01.1.1l-.07-.08a.75.75 0 01.3.6v-.07 5.7a.75.75 0 11-1.5 0V4.43L9.4 15.65a.75.75 0 01-.98.08l-.08-.08a.75.75 0 010-1.06L19.57 3.37h-3.82a.75.75 0 01-.74-.64l-.01-.1a.75.75 0 01.75-.76z" clip-rule="evenodd"></path> </svg> </i>
                </a>
              </div>
            </div>
          </div>
        </div>
      )
}

export default SideBySideVideo