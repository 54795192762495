/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useRef, useState } from "react";
import chatCloseIcon from "assets/images/chat-close-icon.svg";
import chatOpenIcon from "assets/images/chat-icon.svg";

const ChatBot = () => {
  const [isChatOpen, setChat] = useState(false);
  const ai_bot = useRef();

  const handleChat = () => {
    setChat(!isChatOpen);
    ai_bot.current.style.width = "100%";
  };
  const chatIcon = !isChatOpen ? chatOpenIcon : chatCloseIcon;

  return (
    <div
      ref={ai_bot}
      className="gen-ai-bot flex items-end top-0 bottom-0 fixed  right-0 z-50 justify-end mr-0 sm:mr-84 sm:bottom-100_ w-24">
      {!isChatOpen ? (
        <div
          className="close-icon border-4 border-yellow-200 w-20 h-16 mb-36 bg-yellow-500 flex items-center cursor-pointer justify-center border-solid rounded-l-lg"
          onClick={handleChat}>
          <img src={chatIcon} className=" w-9 h-9" alt="" />
        </div>
      ) : null}
      <iframe
        id="chat-bot"
        className={`${
          !isChatOpen ? "hidden" : ""
        } absolute right-0 mb-0 bottom-0 rounded-tl-lg rounded-tr-lg  lg:mr-6 md:mr-6 sm:m-2 lg:w-425 md:w-425`}
        width="100%"
        height="700"
        src="https://gen-ai-demonstration.publicisgroupe.net/Ej-react/build/"></iframe>
    </div>
  );
};

export default ChatBot;
