/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import ChartImages from "./components/molecules/chart-images/chart-images.jsx";
import RichTextEditor from "components/molecules/rich-text-editor/rich-text-editor.jsx";
import Panels from "components/molecules/panels/panels.jsx";
import Accordion from "./components/molecules/accordion/accordion.jsx";
import DynamicTab from "components/molecules/dynamic-tab/dynamic-tab.jsx";
import VideoUrl from "components/video-url/video-url.jsx";
import MegaTab from "components/molecules/mega-tab/mega-tab.jsx";
import CustomFaGrouping from "components/molecules/custom-fa-grouping/custom-fa-grouping.jsx";
import CarouselTiles from "components/molecules/carousel-tiles/carousel-tiles.jsx";
import Resources from "components/molecules/resources/resources.jsx";

const ApiComponent = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://web-dev02.ac.edwardjones.com/us-en/jsonapi/node/landing_page/5e95e871-6827-4a2d-a2bd-41285f18bb91/field_content"
      );
      const jsonData = await response.json();
      setData(jsonData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div>
      {data.map((item, index) => {
        switch (item.type) {
          case "paragraph--chart_images": {
            return <ChartImages item={item} key={index} />;
          }
          case "paragraph--rich_text_editor": {
            return <RichTextEditor item={item} />;
          }
          case "paragraph--panels": {
            return <Panels item={item} />;
          }
          case "paragraph--mega_tab_widget": {
            return <MegaTab />;
          }
          case "paragraph--video_url": {
            return <VideoUrl item={item} />;
          }
          case "paragraph--custom_fa_grouping": {
            return <CustomFaGrouping item={item} />;
          }
          case "paragraph--accordion_component": {
            return <Accordion item={item} />;
          }
          case "paragraph--carousel_tiles": {
            return <CarouselTiles item={item} />;
          }
          case "paragraph--dynamic_tab": {
            return <DynamicTab item={item} />;
          }
          case "paragraph--resources_component": {
            return <Resources item={item} />;
          }
          default: {
            return <p>Default</p>;
          }
        }
      })}
    </div>
  );
};

export default ApiComponent;
