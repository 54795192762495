import React from "react";

const Panels = ({item}) => {
    return (
        <div key={item.id} className="xyx container responsive-grid relative px-0 md:px-4 md:pb-8 overflow-x-scroll md:overflow-x-hidden">
          <div className="col-start-1 col-end-5 lg:row-start-1 md:col-end-8 pr-5 md:pr-0">
            <div className="bg-gray-100 p-10 pb-40 lg:py-28 lg:px-24 lg:pr-36">
              <h2 className="heading-3 md:heading-2 mb-5">{item.attributes.field_heading_text_long}</h2>
              <div className="rich-text text-lg mb-8 min-h-24" dangerouslySetInnerHTML={{__html: item.attributes.field_sub_heading_text.value}} />
              <a data-gtm-category="panel" data-gtm-action="click" data-gtm-label="Click Here" href={item.attributes.field_cta.url} className="btn btn-primary w-full md:w-auto" id="anchor-link-1946"> {item.attributes.field_cta.title} </a></div>
          </div>
          <picture className="col-start-1 col-end-5 -mt-36 lg:row-start-1 lg:mt-20 md:col-start-3 md:col-end-9 lg:col-start-7 lg:col-end-13 pl-5 md:pl-0">
            <source media="(orientation: portrait)"  srcSet={item.relationships.field_image.data.meta.asset_path} />
            <source media="(orientation: landscape) and (min-width: 900px)" srcSet={item.relationships.field_image.data.meta.asset_path} />
            <img data-gtm-category="panel" data-gtm-action="click" data-gtm-label="Click Here" className="w-full lg:h-120 object-cover object-position-full" src={item.relationships.field_image.data.meta.asset_path} alt={item.attributes.field_asset_alt_text} loading="lazy" /> 
          </picture>
        </div>
      )
  }

export default Panels