import React, { useEffect, useState } from "react";
import RichTextEditor from "../rich-text-editor/rich-text-editor.jsx";
import SideBySideVideo from "../side-by-side-video/side-by-side-video.jsx";
import AuthorsLatestArticleVariant from "../authors-latest-article-variant/authors-latest-article-variant.jsx";

const DynamicTab = ({ item }) => {
  const [data, setData] = useState([]);
  const [body, setBody] = useState([]);
  const [urlArr, setUrlArr] = useState([]);

  const fetchData = async (urlVal) => {
    try {
      const response = await fetch(urlVal);
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchAllData = async (urls, body = false) => {
    try {
      const results = await Promise.all(urls.map((url) => fetchData(url)));
      const combinedData = [].concat(...results);
      if (body === true) {
        setBody(combinedData);
      } else setData(combinedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const urlArray = item.relationships.field_dynamic_tab.data.map((i) => {
      const type = i.type.split("--");
      const val1 = type[0];
      const val2 = type[1];
      const id = i.id;
      const url = `https://web-dev02.ac.edwardjones.com/us-en/jsonapi/${val1}/${val2}/${id}`;
      return url;
    });
    setUrlArr(urlArray);
    fetchAllData(urlArray);
  }, []);

  useEffect(() => {
    if (data.length && urlArr.length) {
      const urlArrayVal = urlArr.map((i) => {
        const url = i + "/field_tab_content";
        return url;
      });
      fetchAllData(urlArrayVal, true);
    }
  }, [data, urlArr]);

  useEffect(() => {
    const buttons = document.querySelectorAll(".dynamicTab button");
    const contents = document.querySelectorAll(".dynamicTab .panel");
    const navigate = (index) => {
      contents.forEach((content, j) => {
        if (index == j) {
          content.classList.remove("hidden");
        } else {
          content.classList.add("hidden");
        }
      });

      buttons &&
        buttons.forEach((b, i) => {
          if (index == i) {
            b.classList.remove("text-gray-450");
            b.classList.remove("border-b-2");
            b.classList.remove("border-gray-300");

            b.classList.add("text-gray-500");
            b.classList.add("border-b-4");
            b.classList.add("border-blue-500");
            b.classList.add("hover:bg-gray-200");

            b.setAttribute("aria-selected", true);
            b.setAttribute("tabindex", 0);

            b.focus();
          } else {
            b.classList.remove("text-gray-500");
            b.classList.remove("border-b-4");
            b.classList.remove("border-blue-500");
            b.classList.remove("hover:bg-gray-200");

            b.classList.add("text-gray-450");
            b.classList.add("border-b-2");
            b.classList.add("border-gray-300");

            b.setAttribute("aria-selected", false);
            b.setAttribute("tabindex", -1);
          }
        });
    };

    buttons &&
      buttons.forEach((button, index) => {
        // Adding on click listener for button elements
        button.addEventListener("click", (e) => {
          if (e.target == button) {
            navigate(index);
          }
        });

        // Adding event listener for navigation using keyboard
        button.addEventListener("keydown", (e) => {
          if (e.keyCode == 39) {
            //Right Key Navigation
            const isLastElement = buttons.length == index + 1;
            const nextIndex = isLastElement ? 0 : index + 1;
            navigate(nextIndex);
          }

          if (e.keyCode == 37) {
            const lastIndex = buttons.length - 1;
            const prevIndex = index == 0 ? lastIndex : index - 1;
            navigate(prevIndex);
          }
        });
      });
  });

  return (
    <div class="container dynamicTab pb-5 md:pb-6 lg:pb-0 mt-10 mb-10">
      <h3
        class="text-xl md:text-3xl font-bold mb-2 tab-main-heading"
        data-testid="dtc-heading">
        {item.attributes.field_heading_dynamic_tab}
      </h3>
      <div>
        <div class="overflow-auto h-auto no-scrollbar">
          <div
            data-testid="dtc-tabs"
            role="tablist"
            aria-orientation="horizontal"
            className="px-1.5 flex h-12 overflow-auto no-scrollbar">
            {body.length &&
              data.map((i, index) => {
                return (
                  <button
                    data-gtm-category="dynamic tab component"
                    data-gtm-action="mega tab click"
                    data-gtm-label={i.data.attributes.field_tab_name}
                    data-testid={`dtc-tab-${index}`}
                    class={`relative top-2 h-10 px-4 whitespace-nowrap text-lg font-bold w-full text-gray-450 border-b-4 hover:bg-gray-200 ${
                      index === 0 ? "border-blue-500" : ""
                    }`}
                    aria-controls={`tabs--1--panel--${index}`}
                    aria-selected={index === 0 ? true : false}
                    role="tab"
                    tabindex={index === 0 ? 0 : -1}
                    id={`tabs--1--tab--${index}`}
                    type="button"
                    // onclick="dynamicTabRedirect('{{item.entity|translation.field_tab_name.value}}','{{paragraph.field_heading_dynamic_tab.value}}')"
                  >
                    {i.data.attributes.field_tab_name}
                  </button>
                );
              })}
          </div>
        </div>
        <div
          class="tabpanels mt-8 md:mt-12 border-gray-300 border-b-2 pb-8 md:pb-16"
          data-testid="dtc-contents">
          {body.length &&
            body.map((i, index) => {
              return (
                <div
                  data-testid={`dtc-content-${index}`}
                  className={`panel ${index === 0 ? "" : "hidden"}`}
                  aria-labelledby={`tabs--1--tab--${index}`}
                  role="tabpanel"
                  tabindex="0"
                  id={`tabs-panel--${index}`}>
                  {body[index].data.map((i) => {
                    switch (i.type) {
                      case "paragraph--rich_text_editor": {
                        return <RichTextEditor item={i} />;
                      }
                      case "paragraph--side_by_side_video_component": {
                        return <SideBySideVideo item={i} />;
                      }
                      case "paragraph--authors_latest_article_variant": {
                        return <AuthorsLatestArticleVariant item={i} />;
                      }
                      default: {
                        return null;
                      }
                    }
                  })}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default DynamicTab;
